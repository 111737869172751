export const APP_NAME = 'clas';
export const DRAWER_WIDTH = 360;
export const SM_BREAKPOINT = 600;
export const MINI_SIDE_PANEL_WIDTH = 61;
export const MINI_SIDE_PANEL_DENSE_WIDTH = 45;

export const INITIAL_VIEWPORT = {
  latitude: 43.3705,
  longitude: -5.2062,
  zoom: 13,
  bearing: 0,
  pitch: 0
};

export const ACTIONS_ID = {
  DASHBOARD: 'dashboard',
  MAP: 'map'
};

export const PNOA_HISTORICO_ID = 'pnoa-historico';
export const PNOA_DEFAULT_YEAR = 2006;

export const MAPSTYLES = [
  {
    label: 'PNOA',
    thumbnail: '/mapstyles/pnoa.png',
    id: '/mapstyles/pnoa.json'
  },
  {
    id: PNOA_HISTORICO_ID,
    label: 'PNOA Histórico',
    thumbnail: '/mapstyles/pnoa.png'
  },
  {
    label: 'IGN Claro',
    thumbnail: 'https://vts.larioja.org/styles/rioja-claro/8/126/94.png',
    id: 'https://vts.larioja.org/styles/rioja-claro/style.json'
  },
  {
    label: 'IGN',
    thumbnail: 'https://vts.larioja.org/styles/rioja/8/126/94.png',
    id: 'https://vts.larioja.org/styles/rioja/style.json'
  }
];

export const INITIAL_MAPSTYLE = MAPSTYLES[3];

export const PNOA_YEARS = [
  {
    value: 2006,
    label: '2006'
  },
  {
    value: 2009,
    label: '2009'
  },
  {
    value: 2011,
    label: '2011'
  },
  {
    value: 2014,
    label: '2014'
  },
  {
    value: 2017,
    label: '2017'
  },
  {
    value: 2020,
    label: '2020'
  }
];

export const MAP_DETAILS = {
  REDNATURA: 'redNatura'
};

type MAP_DETAIL_STYLES_COLORS_TYPE = {
  id: number | string,
  name: string,
  type: string,
  paint: {
    color: string,
    opacity: number | undefined
  }
}

type MAP_DETAIL_STYLES_TYPE = {
  id: string,
  label: string,
  thumbnail: string,
  colors: Array<MAP_DETAIL_STYLES_COLORS_TYPE>
}


export const MAP_DETAIL_STYLES: Array<MAP_DETAIL_STYLES_TYPE> = [
  {
    id: MAP_DETAILS.REDNATURA,
    label: 'Red Natura',
    thumbnail: '/mapstyles/forestProtection.svg',
    colors: [
      {
        id: 1,
        name: 'Forestal MVMC',
        type: 'fill',
        paint: {
          color: '#5cb816',
          opacity: 1
        }
      }
    ]
  }
];
type ITEM_TYPE = {
  id: string,
  label: string
}

const USES_ARRAY = [
  'BARBECHO SIN PRODUCCIÓN',
  'PASTO ARBUSTIV.DE 5 O MAS AÑOS',
  'ALUBIAS',
  'BERZA',
  'RAYGRAS',
  'VEZA-RAYGRASS',
  'MAÍZ',
  'OTRAS SUPERFICIES FORESTALES',
  'CEBOLLA',
  'CEREZOS',
  'AVENA',
  'VEZA',
  'Agrario',
  'TRIGO BLANDO',
  'MEZCLA VEZA - AVENA',
  'OTROS FRUTALES',
  'HUERTA',
  'PASTIZAL DE 5 O MAS AÑOS',
  'MEZCLA VEZA-TRIGO',
  'CALABAZA',
  'PATATA',
  'HABAS',
  'RAYGRAS ANUAL',
  'MANZANOS',
  'ALFALFA',
  'PASTO ARBOLADO DE 5 O MAS AÑOS',
  'MEZCLA AVENA-CEBADA',
  'CULTIVOS MIXTOS ESP.PRATENSES',
  'PASTOS DE MENOS DE 5 AÑOS',
  'ARÁNDANO',
  'PERALES',
  'EUCALYPTUS',
  'OTRAS UTIL. NO AGRAR.NI FOREST',
  'TRÉBOL',
  'MEZCLA VEZA-CEBADA',
  'KIWI',
  'BARBECHO TRADICIONAL'
];

export const USES: Array<ITEM_TYPE> = USES_ARRAY.sort().map(use => ({
  id: use, label: use
}));

const TYPES_ARRAY = [
  'PS',
  'FO',
  'IM',
  'CA',
  'PA',
  'FY',
  'R',
  'IV',
  'D',
  'TA',
  'ZU',
  'PR',
  'TH'
];

export const TYPES: Array<ITEM_TYPE> = TYPES_ARRAY.sort().map(type => ({
  id: type, label: type
}));


export const CROPS: Array<ITEM_TYPE> = [
  {id: 'I-', label: 'I- - IMPRODUCTIVO'},
  {id: 'F-', label: 'F- - MANZANOS'},
  {id: 'MB', label: 'MB - MONTE BAJO'},
  {id: 'PD', label: 'PD - PRADO'},
  {id: 'FS', label: 'FS - Frutales secano'},
  {id: 'HS', label: 'HS - Huerta secano'},
  {id: 'FG', label: 'FG - ROBLEDAL'},
  {id: 'MM', label: 'MM - PINO RADIATA (Pinus radiata)'},
  {id: 'C-', label: 'C- - LABOR O LABRADÍO  SECANO'},
  {id: 'ES', label: 'ES - Erial -suelo-'},
  {id: 'MT', label: 'MT - Matorral'},
  {id: 'FC', label: 'FC - CASTAÑO (FORESTAL)'},
  {id: 'PD', label: 'PD - PASTIZAL'},
  {id: 'EU', label: 'EU - EUCALYPTUS'},
  {id: 'TCS', label: 'TCS - Tierras de cultivo de secano'},
  {id: 'CP', label: 'CP - Cultivos permanentes'},
  {id: 'PT', label: 'PT - PASTOS'},
  {id: 'TCR', label: 'TCR - Tierras de cultivo de regadío'}
];

export const FERTILIZATION_COLORS = [
  {
    type: 'Fertilizado',
    value: '#A5B83D'
  },
  {
    type: 'No Fertilizado',
    value: '#726953'
  }
];

export const FERTILIZATION: Array<ITEM_TYPE> = [
  {
    id: 'true',
    label: 'Fertilizado'
  },
  {
    id: 'false',
    label: 'No fertilizado'

  }
];